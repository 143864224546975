const InputWithLabel = props => <div className={props.className}>
    <label className="border-label" style={{pointerEvents: "none"}}>{props.label}</label>
    <input
        className="input input-sm"
        id={props.id}
        type={props.type || "text"}
        name={props.name}
        placeholder={props.placeholder !== undefined ? props.placeholder : props.label}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        autoComplete={props.name}
        readOnly={props.readOnly}
        disabled={props.disabled}
    />
</div>;
export default InputWithLabel;