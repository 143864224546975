import InputWithLabel from "../directives/InputWithLabel";
import CoreTools from "../shared/services/CoreTools";
import Server from "../services/Server";
import Notify from "../shared/services/Notify";

const Login = () => {
    return <div className="panel center-screen shadow p-3" style={{width: "300px"}}>
        <h1>Login</h1>
        <Notify.LastMessage />
        <form onSubmit={CoreTools.fromForm(Server.Login.login)}>
            <InputWithLabel label="E-mail Address" name="email" className="mt-2" />
            <InputWithLabel type="password" label="Password" name="password" className="mt-2" />
            <div className="text-right mt-2">
                <button className="btn btn-sm btn-120 bg-color-blue">Login</button>
            </div>
        </form>
    </div>;
};
export default Login;