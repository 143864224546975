import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import CoreTools from './shared/services/CoreTools.js';
import "./shared/services/Notify.jsx";

[
    "https://assets.cammsoft.com/libs/fontawesome-free-6.2.0-web/css/all.min.css",
    "/libs/css/login.css",
    "/libs/css/scheme1.css",
    "/libs/css/spike.css"
].forEach(css => CoreTools.importStyle(css));

Object.assign(document.body.style, {
    "background": `url('https://graphics.cammsoft.com/img/659e6693a51c34cbc723ba08') no-repeat center center fixed`,
    "background-size": "cover",
    "-webkit-background-size": "cover",
    "-moz-background-size": "cover",
    "-o-background-size": "cover"
});

const appDiv = document.createElement("div");
document.body.appendChild(appDiv);
document.title = "CAMM Services | Login";
ReactDOM.createRoot(appDiv).render(<App />);