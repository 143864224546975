import CoreTools from "./CoreTools";

const Api = _url => {
    let request = {
        url: "",
        method: "GET",
        headers: {},
        queryParams: {},
        postParamsString: ""
    };
    
    let errorCb = null;
    const urlParts = _url.split("?", 2);
    request.url = urlParts[0];
    if (urlParts.length > 1) {for (const s of new URLSearchParams(urlParts[1])) {request.queryParams[s[0]] = s[1];}}

    const ajax = (method, url, headers, data, cb) => {
        const xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = () => xhttp.readyState === 4 && cb(xhttp.status, xhttp.getResponseHeader("Content-Type"), xhttp.responseText);
        xhttp.open(method, url, true);
        Object.keys(headers || {}).forEach(key => xhttp.setRequestHeader(key, headers[key]));
        xhttp.send(["POST", "PUT"].includes(method) ? data : undefined);
    };

    const options = {
        method: param => {
            request.method = param.toUpperCase();
            return options;
        },
        error: cb => {
            errorCb = cb;
            return options;
        },
        query: params => {
            if (CoreTools.isObject(params)) {Object.keys(params).forEach(key => request.queryParams[key] = params[key]);}
            else if (typeof(params) === "string") {for (const s of new URLSearchParams(params)) {request.queryParams[s[0]] = s[1];}}
            return options;
        },
        json: params => {
            request.method = "POST";
            request.headers["Content-Type"] = "application/json";
            try {request.postParamsString = JSON.stringify(params);}
            catch (err) {if (typeof(errorCb) === "function") {errorCb(err, request, null);}}
            return options;
        },
        headers: params => {
            Object.keys(params).forEach(key => request.headers[key] = params[key]);
            return options;
        },
        form: params => {
            request.method = "POST";
            request.headers["Content-Type"] = "application/x-www-form-urlencoded";
            let formString = new FormData();
            Object.keys(params).forEach(key => formString.append(key, params[key]));
            request.postParamsString = formString.toString();
            return options;
        },
        string: params => {
            request.method = "POST";
            request.headers["Content-Type"] = "text/plain";
            request.postParamsString = params;
            return options;
        },
        fetch: cb => {
            let url = request.url + (Object.keys(request.queryParams).length ? "?" + new URLSearchParams(request.queryParams).toString() : "");
            ajax(
                request.method,
                url,
                request.headers,
                request.postParamsString,
                (statusCode, contentType, data) => {
                    if ([200, 201].includes(statusCode)) {
                        if ((contentType || "").includes("application/json")) {
                            try {
                                cb({
                                    data: JSON.parse(data),
                                    statusCode: statusCode,
                                    contentType: contentType,
                                    error: null,
                                    request: request
                                });
                            } catch (err) {
                                cb({
                                    data: data,
                                    statusCode: statusCode,
                                    contentType: contentType,
                                    error: err,
                                    request: request
                                });
                            }
                        } else if (contentType.includes("application/x-www-form-urlencoded")) {
                            try {
                                let formObj = {};
                                let formData = new FormData(data);
                                for (const s of formData.entries()) {formObj[s[0]] = s[1];}
                                cb({
                                    data: formObj,
                                    statusCode: statusCode,
                                    contentType: contentType,
                                    error: null,
                                    request: request
                                });
                            } catch (err) {
                                cb({
                                    data: data,
                                    statusCode: statusCode,
                                    contentType: contentType,
                                    error: err,
                                    request: request
                                });
                            }
                        } else {
                            cb({
                                data: data,
                                statusCode: statusCode,
                                contentType: contentType,
                                error: null,
                                request: request
                            });
                        }
                    } else {
                        cb({
                            data: data,
                            statusCode: statusCode,
                            contentType: contentType,
                            error: {message: "Fetch Failed", statusCode: statusCode},
                            request: request
                        });
                    }
                }
            );
        }
    };
    return options;
};

export default Api;