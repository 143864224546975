import Api from "../shared/services/Api";
import CoreTools from "../shared/services/CoreTools";
import Navigate from "../shared/services/Navigate";

const session = {
    email: "",
    firstname: "",
    lastname: "",
    phone: "",
    permissions: ["Everyone"]
};

const watchers = {
    assign: (arr, cb) => {const watch = {cb: cb}; arr.push(watch); return () => CoreTools.remove(arr, watch);},
    notify: (arr, data) => arr.filter(w => typeof(w.cb) === "function").forEach(w => w.cb(data)),
    sessions: []
};

const api = (endpoint, obj, cb) => {
    CoreTools.log("API Request:", endpoint, obj);
    Api("/api" + endpoint).json(CoreTools.isObject(obj) ? obj : {}).fetch(results => {
        if (results.statusCode === 200 && CoreTools.isObject(results.data)) {
            CoreTools.log("API Response:", endpoint, results.data);
            if (results.data.message) {Array.isArray(results.data.message) ? window.ctrl.notify(...results.data.message) : window.ctrl.notify(results.data.message, "info", 10);}
            if (results.data.console) {Array.isArray(results.data.console) ? console.log(...results.data.console) : console.log(results.data.console);}
            if (results.data.setKey !== undefined) {CoreTools.memory.set("session-id", results.data.setKey, 60);}
            if (results.data.navigate) {Array.isArray(results.data.navigate) ? Navigate.set(...results.data.navigate) : Navigate.set(results.data.navigate);}
            if (typeof(cb) === "function") {cb(results.data);}
        } else {
            window.ctrl.notify(`API Request Failed\nURL: ${endpoint}`, "faliure", 10);
            if (typeof(cb) === "function") {cb({success: false});}
        }
    });
};

const Server = {
    watchSessionUpdate: cb => watchers.assign(watchers.sessions, cb),
    getSession: () => CoreTools.clone(session),
    setMessage: (message, status, time) => window.ctrl.notify(message, status || "blue", time || 5),
    Login: {
        login: values => api("/System/Login", values)
    }
};

export default Server;