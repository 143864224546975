import CoreTools from "./CoreTools";

const pc = {
    blue: [50, 50, 180],
    red: [200, 50, 50], // Good
    green: [50, 200, 50],
    yellow: [200, 200, 50], // Good
    purple: [200, 50, 200],
    grey: [100, 100, 100],
    teal: [50, 200, 200], // Good
    white: [240, 240, 240]
};

const limits = e => CoreTools.switch([e > 255, 255], [e < 0, 0], [true, e]);

CoreTools.setTheme({
    "--color-blue": `rgb(${pc.blue.join()})`,
    "--color-blue-hl": `rgb(${pc.blue.map(e => limits(e + 50)).join()})`,
    "--color-blue-fd": `rgb(${pc.blue.map(e => limits(e - 30)).join()})`,
    "--color-blue-dk": `rgb(${pc.blue.map(e => limits(e - 50)).join()})`,
    "--color-red": `rgb(${pc.red.join()})`,
    "--color-red-hl": `rgb(${pc.red.map(e => limits(e + 50)).join()})`,
    "--color-red-fd": `rgb(${pc.red.map(e => limits(e - 15)).join()})`,
    "--color-red-dk": `rgb(${pc.red.map(e => limits(e - 30)).join()})`,
    "--color-green": `rgb(${pc.green.join()})`,
    "--color-green-hl": `rgb(${pc.green.map(e => limits(e + 50)).join()})`,
    "--color-green-fd": `rgb(${pc.green.map(e => limits(e - 15)).join()})`,
    "--color-green-dk": `rgb(${pc.green.map(e => limits(e - 30)).join()})`,
    "--color-yellow": `rgb(${pc.yellow.join()})`,
    "--color-yellow-hl": `rgb(${pc.yellow.map(e => limits(e + 50)).join()})`,
    "--color-yellow-fd": `rgb(${pc.yellow.map(e => limits(e - 15)).join()})`,
    "--color-yellow-dk": `rgb(${pc.yellow.map(e => limits(e - 30)).join()})`,
    "--color-purple": `rgb(${pc.purple.join()})`,
    "--color-purple-hl": `rgb(${pc.purple.map(e => limits(e + 50)).join()})`,
    "--color-purple-fd": `rgb(${pc.purple.map(e => limits(e - 15)).join()})`,
    "--color-purple-dk": `rgb(${pc.purple.map(e => limits(e - 30)).join()})`,
    "--color-grey": `rgb(${pc.grey.join()})`,
    "--color-grey-hl": `rgb(${pc.grey.map(e => limits(e + 50)).join()})`,
    "--color-grey-fd": `rgb(${pc.grey.map(e => limits(e - 15)).join()})`,
    "--color-grey-dk": `rgb(${pc.grey.map(e => limits(e - 30)).join()})`,
    "--color-teal": `rgb(${pc.teal.join()})`,
    "--color-teal-hl": `rgb(${pc.teal.map(e => limits(e + 50)).join()})`,
    "--color-teal-fd": `rgb(${pc.teal.map(e => limits(e - 15)).join()})`,
    "--color-teal-dk": `rgb(${pc.teal.map(e => limits(e - 30)).join()})`,
    "--color-white": `rgb(${pc.white.join()})`,
    "--color-white-hl": `rgb(${pc.white.map(e => limits(e + 50)).join()})`,
    "--color-white-fd": `rgb(${pc.white.map(e => limits(e - 15)).join()})`,
    "--color-white-dk": `rgb(${pc.white.map(e => limits(e - 30)).join()})`
});

