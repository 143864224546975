import "./shared/services/Colors";
import CoreTools from "./shared/services/CoreTools";
import Modal from "./directives/Modal";
import Login from "./screens/Login";
import Navigate from "./shared/services/Navigate"
import React from "react";

const TopBanner = () => <div className="banner-top">
    <img src="https://graphics.cammsoft.com/img/659fb24fed4091ec75de07c9" className="logo-banner-top" alt="CammService Logo" />
</div>;

const BottomBanner = () => <div className="banner-bottom">
    <span>Copyright © {new Date().getFullYear()} CAMM Services. All Rights Reserved.</span>
</div>;

const App = () => {
    const [hash, setHash] = React.useState(Navigate.get());
    React.useEffect(() => Navigate.watch(setHash), [setHash]);
    return <div>
        <TopBanner />
        <BottomBanner />
        {CoreTools.switch(
            [hash === "#Login", () => <Login />],
            [true, () => <Login />]
        )}
        <Modal.Parent />
    </div>;
};

export default App;
