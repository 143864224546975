let location = (window.location.hash || "#");
const depths = location.substring(1).split("|");
const watchers = [];

window.addEventListener("popstate", event => {
    if (location !== (window.location.hash || "#")) {
        location = (window.location.hash || "#");
        const nDepths = location.substring(1).split("|");
        const l = (nDepths.length > depths.length ? nDepths.length : depths.length);
        for (let i = 0; i <= l; i++) {
            if (depths[i] !== nDepths[i]) {
                depths[i] = nDepths[i];
                watchers.filter(w => w.depth === i).forEach(w => w.cb(nDepths[i]));
            }
        }
        watchers.filter(w => [null, false, undefined].includes(w.depth)).forEach(w => w.cb(depths));
    }
});

const Navigate = {
    get: depth => [null, false, undefined].includes(depth) ? depths : depths[depth],
    set: (url, delay) => url && setTimeout(() => window.location.href = url, delay),
    watch: (depth, cb) => {
        const watch = {depth: depth, cb: cb};
        watchers.push(watch);
        return () => {
            const index = watchers.indexOf(watch);
            if (index > -1) {watchers.splice(index, 1);}
        };
    }
};

export default Navigate;