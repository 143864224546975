import React from "react";
import CoreTools from "../shared/services/CoreTools";

let lastModalKey = 0;
const watchers = [];
const aModals = [];

const Child = props => <div className="modal-mask">
    <div className={["modal-container", props.className].filter(e => e).join(" ")} style={props.style}>
        {props.children}
    </div>
</div>;

const Parent = props => {
    const [modals, setModals] = React.useState([]);
    React.useEffect(() => {
        const watch = {cb: modalSet => setModals([...modalSet])};
        watchers.push(watch);
        return () => CoreTools.remove(watchers, watch);
    }, [setModals]);
    return modals;
};

const Load = (modalElement, props, cb) => {
    const modal = <Child key={++lastModalKey} style={props.style} className={props.className}>{
        React.cloneElement(
            modalElement, 
            Object.assign(
                CoreTools.isObject(props) ? props : {},
                {
                    close: (...res) => {
                        if (typeof(cb) === "function") {cb(...res);}
                        removeModal();
                    }
                }
            )
        )    
    }</Child>;
    const removeModal = () => {
        CoreTools.remove(aModals, modal);
        watchers.forEach(w => w.cb(aModals));
    };
    aModals.push(modal);
    watchers.forEach(w => w.cb(aModals));
    return {
        close: removeModal
    };
};

const Modal = {
    Child: Child,
    Parent: Parent,
    Load: Load
};

export default Modal;