import React from "react";
import CoreTools from "./CoreTools";

const watchers = [];
const watch = cb => {
    const w = {cb: cb};
    watchers.push(w);
    return () => watchers.splice(watchers.indexOf(w), 1);
};

const addMessage = (() => {
    console.log("Message Setup");
    let messageCount = 0;
    const msgBox = document.createElement("div");
    Object.assign(msgBox.style, {
        position: "fixed",
        bottom: "10px",
        right: "10px",
        userSelect: "none",
        pointerEvents: "none",
        textAlign: "right"
    });
    const moveEventEvent = event => msgBox.style.opacity = (
        event.clientX > msgBox.offsetLeft && 
        event.clientX < msgBox.offsetLeft + msgBox.offsetWidth &&
        event.clientY > msgBox.offsetTop && 
        event.clientY < msgBox.offsetTop + msgBox.offsetHeight
    ) ? 0.1 : 1;
    return (msg, status, time) => {
        if (messageCount === 0) {
            document.body.appendChild(msgBox);
            window.addEventListener("mousemove", moveEventEvent);
        }
        messageCount++;
        const messageContainer = document.createElement("div");
        const message = document.createElement("span");
        message.className = `badge-${status}`;
        Object.assign(message.style, {
            display: "block",
            margin: "2px",
            fontSize: "20px",
            whiteSpace: "nowrap",
            textAlign: "left",
            pointerEvents: "none"
        });
        message.innerHTML = msg.split("\n").join("<br />");
        messageContainer.appendChild(message);
        msgBox.appendChild(messageContainer);
        msgBox.style.zIndex = CoreTools.topIndex() + 5;
        setTimeout(() => {
            msgBox.removeChild(messageContainer);
            messageCount--;
            if (messageCount === 0) {
                window.removeEventListener("mousemove", moveEventEvent);
                msgBox.style.opacity = 1;
                document.body.removeChild(msgBox);
            }
        }, time);
    };
})();

let smIndex = 0;
let smTimeout = {};

if (!window.ctrl) window.ctrl = {};

window.ctrl.notify = (message, status, time) => {
    addMessage(message, status || "info", (time || 10) * 1000);
    watchers.forEach(w => w.cb(message, status || "info", (time || 10) * 1000));
};

const Notify = {
    LastMessage: props => {
        const [serverMessage, setServerMessage] = React.useState(false);
        const [smi] = React.useState((++smIndex).toString());
        React.useEffect(() => watch((msg, status, time) => {
            clearTimeout(smTimeout[smi]);
            setServerMessage({msg: msg, status: status || "info"});
            smTimeout[smi] = setTimeout(() => {
                setServerMessage(false);
                delete(smTimeout[smi]);
            }, time);
        }), [setServerMessage, smi]);
        return serverMessage && <div className={props.className} style={props.style}>
            {serverMessage.msg.split("\n").map((msg, index) => <div key={index} className={`text-${serverMessage.status} text-center`}>{msg}</div>)}
        </div>;
    }
};

export default Notify;